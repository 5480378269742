* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}


body{
  background-color: #282c34;
}

@font-face {
  font-family: 'W95FA';
  src: url('/public/fonts/w95fa.woff2') format('woff2'),
       url('/public/fonts/w95fa.woff') format('woff'),
       url('/public/fonts/W95FA.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

.cursor:hover{
  cursor: pointer;
}